@import "./base.scss";

/* BASE */
@font-face {
  font-family: "Bebas";
  src: url("/fonts/bebas-regular?.eot"); /* IE9 Compat Modes */
  src: url("/fonts/bebas-regular?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/bebas-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/fonts/bebas-regular.woff") format("woff"),
    /* Pretty Modern Browsers */ url("/fonts/bebas-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("/fonts/bebas-regular.svg#svgBebas") format("svg"); /* Legacy iOS */
}

body {
  font-size: $font-size;
  color: $txt-color;
  font-family: $font-family;
  background-color: $content-bg;
  font-weight: $font-light;

  @media all and (max-width: $bp-laptop) {
    font-size: 18px;
  }
  @media all and (max-width: $bp-tablet) {
    font-size: 16px;
  }
  @media all and (max-width: $bp-responsive) {
    font-size: 16px;
    font-weight: $font-medium;
  }
  @media all and (max-height: 800px) {
    font-size: 16px;
  }
  @media all and (max-height: 600px) {
    font-size: 14px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
form,
section {
  margin: 0;
  padding: 0;
  font-family: $font-family;
}

.pointer {
  cursor: pointer;
}
a {
  text-decoration: none;
  color: $link-color;
}
b {
  font-weight: $font-bold;
}

h1 {
  font-size: $font-size-xl;
  color: $base-color;
}
h2 {
  font-size: $font-size-xxxlg;
  font-weight: 600;
  color: $base-color;
}
h3 {
  font-size: $font-size-xxlg;
  color: $base-color;
}
h4 {
  font-size: $font-size-xlg;
  color: $color-1;
}
h5 {
  font-size: $font-size-md;
}

p,
ul {
  line-height: 1.5em;
}
.txt-center {
  text-align: center;
}
.container {
  padding-left: 25px;
  padding-right: 25px;
}

.row {
  @extend %flex;
  margin-left: -15px;
  margin-right: -15px;
}
.col {
  @extend %col;
  flex-basis: 0;
  flex-grow: 1;
  padding-left: 15px;
  padding-right: 15px;
}
/* BASE STYLES */

.font-size-xxxl {
  font-size: $font-size-xxxl; //291px
}
.font-size-xxl {
  font-size: $font-size-xxl; //132px
}
.font-size-xl {
  font-size: $font-size-xl; //44px
}
.font-size-xxxlg {
  font-size: $font-size-xxxlg; //34px
}
.font-size-xxlg {
  font-size: $font-size-xxlg; //30px
}
.font-size-xlg {
  font-size: $font-size-xlg; //28px
}
.font-size-lg {
  font-size: $font-size-lg; //24px
}
.font-size-md {
  font-size: $font-size-md; //22px
}
.font-size {
  font-size: $font-size;
}

.thin {
  font-weight: $font-thin;
}
.extralight {
  font-weight: $font-extralight;
}
.light {
  font-weight: $font-light;
}
.regular {
  font-weight: $font-regular;
}
.medium {
  font-weight: $font-medium;
}
.semibold {
  font-weight: $font-semibold;
}
.bold {
  font-weight: $font-bold;
}

.color-base {color:$base-color;}
.color-1 {
  color: $color-1;
}
.color-2 {
  color: $color-2;
}
.color-3 {
  color: $color-3;
}
.color-4 {
  color: $color-4;
}

.uppercase {
  text-transform: uppercase;
}
.notxttransform {
  text-transform: none;
}
.txt-overlay {
  opacity: 0.05 !important;
}
.bullets {
  list-style: none;
  li {
    position: relative;
    padding-left: 30px;
    &:before {
      content: "";
      position: absolute;
      top: 5px;
      left: 0;
      display: block;
      width: 15px;
      height: 15px;
      background-color: $color-3;
      @include transform(rotate(45deg));
    }
  }
  @media all and (max-width: $bp-responsive) {
    li {
      &:before {
        width: 10px;
        height: 10px;
      }
    }
  }
  @media all and (max-height: 800px) {
    li {
      &:before {
        width: 10px;
        height: 10px;
      }
    }
  }
}

.bttn {
  position: relative;
  padding: 10px;
  background-color: #ffffff;
  text-align: center;
  @extend %flex-inline;
  justify-content: center;
  align-items: center;
  font-weight: $font-bold;
  text-transform: uppercase;
  border-radius: 5px;
}

/* FULLBG VIDEO */

.video-bg {
  position: absolute;
  left: 50%;
  @include transform(translateX(-50%));
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  z-index: 0;
}
.video-bg .video,
.video-bg .video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // pointer-events: none;
}
@media (min-aspect-ratio: 16/9) {
  .video-bg .video {
    height: 300%;
    top: -100%;
  }
}
@media (max-aspect-ratio: 16/9) {
  .video-bg .video {
    width: 300%;
    left: -100%;
  }
}

.video-responsive {
  overflow: hidden;
  position: relative;
  width: 100vw;
  &:after {
    position: relative;
    padding-top: 56.25%;
    // 16:9 -> (9 is 56.25% of 16).
    // 4:3 -> set it to 75%.
    display: block;
    content: "";
  }
  iframe,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
