@import "../../base";

.home {

  .nav-indicator {
    position:fixed;
    z-index:7;
    top:50%;
    @include transform(translateY(-50%));
    right:1vw;
    > div {
      cursor:pointer;
      margin:15px 0;
      height:10px;
      width:10px;
      border-radius: 10px;
      background-color:$color-3; //$color-2;$base-color
      &.active {
        background-color:$color-1;
      }
    }
    @media all and (max-width: 900px) {
      display:none;
    }
  }

  img.arrows-pointer {
    display:block;
    width:40px;
    height:auto;
    cursor:pointer;
    @media all and (max-width: 1440px) {
      width:35px;
    }
    @media all and (max-height: 600px) {
      width:30px;
    }
  }

  .section {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    overflow: hidden !important;
    background-color: $content-bg;

    &.animated-off {
      z-index: 10 !important;
    }
    &.animated {
      z-index: 9 !important;
    }
    &.landing-animation {
      z-index: 8 !important;
    }
    &.inactive {
      z-index: -1 !important;
    }
    &.active {
      z-index: 100 !important;
    }

    &.section-0 {
      z-index: 6;
    }
    &.section-1 {
      z-index: 5;
    }
    &.section-2 {
      z-index: 4;
    }
    &.section-3 {
      z-index: 3;
    }
    &.section-4 {
      z-index: 2;
    }
    &.section-5 {
      z-index: 1;
    }

    // &.section-0,
    // &.section-1,
    // &.section-2,
    // &.section-3,
    // &.section-4,
    // &.section-5 {
    //   display: none;
    // }
  }

  .section-header {
    z-index: 10;
    position: absolute;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 30px;
    top: 0;
    left: 0;
    right: 0;
    width: $header-width;
    @extend %flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px dashed $base-color;
    &:before,
    &:after {
      z-index: 10;
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      overflow: hidden;
      height: 2px;
      width: 50%;
      background-repeat: repeat-x;
      background-position: center;
      background-size: 20px auto;
    }
    &:before {
      left: 0;
      background-position: left center;
      background-image: url("/imgs/dash.png");
    }
    &:after {
      right: 0;
      background-position: right center;
      background-image: url("/imgs/dash.png");
    }

    .logo {
      cursor: pointer;
      img {
        display: block;
        width: 12vw;
        @media all and (max-height:700px){
          max-height:50px;
          width: auto;
        }
      }
    }
    .txt {
      cursor: pointer;
      white-space: nowrap;
      font-size: $font-size-md;
      color: $color-4;
      font-weight: 400;
    }
    .line-icon {
      position: absolute;
      bottom: 0;
      @include transform(translate(-50%, 50%));
      img {
        display: block;
        height: 40px;
        width: auto;
        max-width: none;
      }
    }

    @media all and (max-height: 800px) {
      padding-top: 15px;
      padding-bottom: 15px;
      .line-icon {
        img {
          height: 30px;
          width: auto;
          max-width: none;
        }
      }
    }
  }
}
