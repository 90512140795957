@import "../../base.scss";

.section-1 {
    text-align: center;
    // opacity: 0;
    .section-header {
        border: 0;
        &:before {
            left: 0;
            background-image: url("/imgs/dash-white.png");
        }
        &:after {
            right: 0;
            background-image: url("/imgs/dash.png");
        }
        .line-icon {
            @include transform(translateY(50%));
            left: 0;
        }
    }

    .txt-overlay {
        z-index: 3;
        position: absolute;
        top: 20%;
        left: 100%;
        font-size: $font-size-xxxl;
        white-space: nowrap;
        color: $color-1;
        font-family: "Bebas", sans-serif;
        opacity: 0.05;
    }

    .cell-1 {
        &:before { // triggers mouse wheel
            z-index:1;
            position: absolute;
            width:100%;
            height:100%;
            top:0;
            left:0;
            content:"";
            display:block;
        }
        z-index: 2;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        overflow: hidden;
        text-align: center;
        .video-logo {
            z-index: 1;
            color: #ffffff;
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            text-align: center;
            h2 {
                font-weight: $font-bold;
                text-transform: uppercase;
                padding-top: 20px;
                color: #ffffff;
            }
            img {
                display: block;
                margin: auto;
                width: 18vw;
                height: auto;
            }
        }
        .video-bg {
            background-image: url("/imgs/section-1.jpg");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
    .cell-2 {
        z-index: 3;
        position: absolute;
        width: 50%;
        height: 100%;
        top: 0;
        right: 0;
        @extend %flex;
        align-items: flex-end;
        justify-content: center;
        @media all and (max-height: 680px) {
            padding-top: 80px;
        }

        > div {
            text-align: left;
            width: $content-width;
            padding-bottom: 60px;
            @media all and (max-height: 800px) {
                padding-bottom: 40px;
            }
            @media all and (max-height: 680px) {
                padding-bottom: 20px;
            }

            h2 {
                padding-bottom: 30px;
                span {
                    display: block;
                }
                @media all and (max-height: 800px) {
                    padding-bottom: 30px;
                }
                @media all and (max-height: 680px) {
                    padding-bottom: 20px;
                }
            }
            p {
                padding-bottom: 30px;
                &:last-child {
                    padding-bottom: 0;
                }
                @media all and (max-height: 800px) {
                    padding-bottom: 30px;
                }
                @media all and (max-height: 680px) {
                    padding-bottom: 20px;
                }
            }
            .max-width {
                width: 100%;
                max-width: 620px;
            }
        }
    }
}


/* LANDING ANIMATION 
- started once on loading */

.section-1.landing-animation {

    #video-logo-1 {
        @include transform(translateY(-30%));
        opacity: 1;
        animation-name: landing-video-logo-1;
        animation-duration: 5s;
        animation-delay: 1s;
        @include animation-fill-mode(forwards);
        animation-timing-function: linear;
    }
    @keyframes landing-video-logo-1 {
        0% {
            @include transform(translateY(-30%));
            opacity: 1;
        }
        80% {
            @include transform(translateY(-50%));
            opacity: 1;
        }
        100% {
            @include transform(translateY(-50%));
            opacity: 0;
        }
    }

    #video-txt-1 {
        opacity: 0;
        animation-name: landing-video-txt-1;
        animation-duration: 5s;
        animation-delay: 1s;
        @include animation-fill-mode(forwards);
        animation-timing-function: linear;
    }
    @keyframes landing-video-txt-1 {
        0% {
            opacity: 0;
        }
        80% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    #video-box-1 {
        width: 100%;
        animation-name: landing-video-box-1;
        animation-duration: 1s;
        animation-delay: 8s;
        @include animation-fill-mode(forwards);
        animation-timing-function: linear;
    }
    @keyframes landing-video-box-1 {
        0% {
            width: 100%;
        }
        100% {
            width: 50%;
        }
    }

    #txt-1 {
        @include transform(translateX(0));
        opacity: 0.05 !important;
        animation-name: landing-txt-1;
        animation-duration: 1s;
        animation-delay: 8s;
        @include animation-fill-mode(forwards);
        animation-timing-function: linear;
    }
    @keyframes landing-txt-1 {
        0% {
            @include transform(translateX(0));
            opacity: 0.05 !important;
        }
        100% {
            @include transform(translateX(-100%));
            opacity: 0.05 !important;
        }
    }

    #cell-text-1,
    #section-header-1 {
        opacity: 0;
        animation-name: landing-cell-text-1;
        animation-duration: 1s;
        animation-delay: 9s;
        @include animation-fill-mode(forwards);
        animation-timing-function: linear;
    }
    @keyframes landing-cell-text-1 {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}



/* ANIMATIONS
    Тази анимация трябва да се случва в рамките на 5s, защото се стартира заедно с анимацията от Section-0 и трябва да са синхронизирани.
    При излизане от "section-1", за да се зареди "section-2" се добавя "animated-off" към "section-1" и клас "animated" към "section-2"
*/

.section-1.animated {
    // animation-name: section-1;
    // animation-duration: 2s;
    // @include animation-fill-mode(forwards);
    // animation-timing-function: linear;
    // @keyframes section-1 {
    //     0% {
    //         opacity: 0;
    //     }
    //     100% {
    //         opacity: 1;
    //     }
    // }

    #video-logo-1 {
        @include transform(translateY(-30%));
        opacity: 1;
        animation-name: video-logo-1;
        animation-duration: 2s;
        animation-delay: 1s;
        @include animation-fill-mode(forwards);
        animation-timing-function: linear;
    }
    @keyframes video-logo-1 {
        0% {
            @include transform(translateY(-30%));
            opacity: 1;
        }
        80% {
            @include transform(translateY(-50%));
            opacity: 1;
        }
        100% {
            @include transform(translateY(-50%));
            opacity: 0;
        }
    }

    #video-txt-1 {
        opacity: 0;
        animation-name: video-txt-1;
        animation-duration: 2s;
        animation-delay: 1s;
        @include animation-fill-mode(forwards);
        animation-timing-function: linear;
    }
    @keyframes video-txt-1 {
        0% {
            opacity: 0;
        }
        80% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    #video-box-1 {
        width: 100%;
        animation-name: video-box-1;
        animation-duration: 1s;
        animation-delay: 3s;
        @include animation-fill-mode(forwards);
        animation-timing-function: linear;
    }
    @keyframes video-box-1 {
        0% {
            width: 100%;
        }
        100% {
            width: 50%;
        }
    }

    #txt-1 {
        @include transform(translateX(0));
        opacity: 0.05 !important;
        animation-name: txt-1;
        animation-duration: 1s;
        animation-delay: 3s;
        @include animation-fill-mode(forwards);
        animation-timing-function: linear;
    }
    @keyframes txt-1 {
        0% {
            @include transform(translateX(0));
            opacity: 0.05 !important;
        }
        100% {
            @include transform(translateX(-100%));
            opacity: 0.05 !important;
        }
    }

    #cell-text-1,
    #section-header-1 {
        opacity: 0;
        animation-name: cell-text-1;
        animation-duration: 1s;
        animation-delay: 4s;
        @include animation-fill-mode(forwards);
        animation-timing-function: linear;
    }
    @keyframes cell-text-1 {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}









/* STATE */

.section-1.state {
    opacity: 1;
    #video-logo-1,
    #video-txt-1 {
        opacity: 0;
        display: none;
    }
    #video-box-1 {
        width: 50%;
        .video-logo {
            @include transform(translateY(-30%));
        }
    }
    #txt-1 {
        @include transform(translateX(-100%));
    }
    #cell-text-1,
    #section-header-1 {
        opacity: 1;
    }
}

/* ANIMATED-OFF*/

.section-1.animated-off {
    opacity: 1;
    animation-name: section-1-off;
    animation-duration: 0.7s;
    animation-delay: 0.7s;
    @include animation-fill-mode(forwards);
    animation-timing-function: linear;
    @keyframes section-1-off {
        0% {
            @include transform(translateX(0));
        }
        100% {
            @include transform(translateX(100%));
        }
    }

    #video-logo-1,
    #video-txt-1 {
        opacity: 0;
        display: none;
    }
    #video-box-1 {
        z-index: 5;
        width: 50%;
        animation-name: video-box-1-off;
        animation-duration: 0.7s;
        @include animation-fill-mode(forwards);
        animation-timing-function: linear;
    }
    @keyframes video-box-1-off {
        0% {
            width: 50%;
        }
        100% {
            width: 100%;
        }
    }

    // #txt-1,
    #cell-text-1,
    #section-header-1 {
        opacity: 1;
        animation-name: cell-text-1-off;
        animation-duration: 0.7s;
        @include animation-fill-mode(forwards);
        animation-timing-function: linear;
    }
    @keyframes cell-text-1-off {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
} // &.animated-off
