// Fonts
$font-family: "Montserrat", sans-serif;
$font-thin: 100;
$font-extralight: 200;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-extrabold: 900;

// Font-size
$font-size-xxxl: 14.55em; //291px
$font-size-xxl: 6.6em; //132px
$font-size-xl: 2.2em; //44px
$font-size-xxxlg: 1.7em; //34px
$font-size-xxlg: 1.5em; //30px
$font-size-xlg: 1.4em; //28px
$font-size-lg: 1.2em; //24px
$font-size-md: 1.1em; //22px
$font-size: 20px;
$font-size-sm: 0.9em; //18px
$font-size-xs: 0.8em; //16px
$font-size-xxs: 11px;

// Colors
$txt-color: #01102a;
$base-color: #6e3e98;
$color-1: #a21bcb;
$color-2: #2d2685;
$color-3:#5C24FB;
$color-4: #3D1BCD;

$link-color: #5C24FB;
$content-bg: #f2f4ff;

// Layout
/*
Typical resolutions for some popular screen sizes:
19-inch screen (standard ratio): 1280 x 1024 pixels.
20-inch screen (standard ratio): 1600 x 1200 pixels.
22-inch screen (widescreen): 1680 x 1050 pixels.
24-inch screen (widescreen): 1900 x 1200 pixels.
*/
$bp-desktop: 1600px;
$bp-laptop: 1440px;
$bp-tablet: 1024px;
$bp-responsive: 900px;
$bp-mobile: 768px;
$bp-mobile-sm: 680px;

$header-width: 95%;
$content-width: 85%;

$sidespace-xs: 10px;
$sidespace-sm: 15px;
$sidespace: 25px;
$sidespace-md: 30px;
$sidespace-lg: 45px;
$sidespace-xl: 50px;
$sidespace-xxl: 65px;
$sidespace-xxxl: 75px;

// styles
$txt-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);

$animation: all 0.5s ease-in-out;

// cross-brawser styles

%flex {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
} //  @extend %flex;
%flex-inline {
    display: inline-flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-inline-flex;
}
%col {
    -ms-flex-preferred-size: 0;
    -ms-flex-positive: 1;
    max-width: 100%;
    flex-wrap: wrap;
}

// %animation {
//     -webkit-transition: all 0.5s ease-in-out;
//     -moz-transition: all 0.5s ease-in-out;
//     -ms-transition: all 0.5s ease-in-out;
//     -o-transition: all 0.5s ease-in-out;
//     transition: all 0.5s ease-in-out;
// }

%ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
} //  @extend %ellipsis;

%background-cover {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
} //  @extend %background-cover;

@mixin transition($property) {
    -webkit-transition: $property;
    -moz-transition: $property;
    -ms-transition: $property;
    -o-transition: $property;
    transition: $property;
} // .clasname { @include transition(all 0.5s ease-in-out); }

@mixin transform($property) {
    -webkit-transform: $property;
    -moz-transform: $property;
    -ms-transform: $property;
    -o-transform: $property;
    transform: $property;
} // @include transform();

@mixin box-shadow($property) {
    -webkit-box-shadow: $property;
    -moz-box-shadow: $property;
    -ms-box-shadow: $property;
    box-shadow: $property;
} // @include box-shadow($box-shadow);

@mixin text-shadow($property) {
    -webkit-text-shadow: $property;
    -moz-text-shadow: $property;
    -ms-text-shadow: $property;
    text-shadow: $property;
} // @include text-shadow($txt-shadow);

@mixin animation-fill-mode($property) {
    -webkit-animation-fill-mode: $property; /* Chrome 16+, Safari 4+ */
    -moz-animation-fill-mode: $property;    /* FF 5+ */
    -o-animation-fill-mode: $property;      /* Not implemented yet */
    -ms-animation-fill-mode: $property;     /* IE 10+ */
    animation-fill-mode: $property;  /* When the spec is finished */
} // .clasname { @include animation-fill-mode() }
