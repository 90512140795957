@import "../../base";

.section-0 {
    background-color: transparent;
    color: #ffffff;
    &:before {
        z-index:0;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(92, 36, 251);
        background: linear-gradient(45deg, rgba(92, 36, 251, 1) 0%, rgba(110, 62, 152, 1) 100%);
    }
    .logo {
        z-index:2;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        text-align: center;
        h1 {
            font-weight: $font-bold;
            text-transform: uppercase;
            padding-top: 20px;
        }
        img {
            display: block;
            margin: auto;
            width: 29vw;
            height: auto;
        }
    }
    .txt {
        z-index:1;
        position: absolute;
        color: rgba(255, 255, 255, 0.06);
        font-size: $font-size-xxl;
        white-space: nowrap;
        font-weight: $font-bold;
        text-transform: uppercase;
        &#txt-0-1 {
            top: 10%;
        }
        &#txt-0-2 {
            bottom: 10%;
        }
    }
}


/* ANIMATIONS
    Всичко се слува за 5s
    section-0 и section-1 се стартират заедно
*/
.section-0.animated {
    background-color:transparent;
    &#section-0 {
        opacity: 1;
        &:before {
            opacity: 1;
            animation-name: section-0;
            animation-duration: 5s;
            @include animation-fill-mode(forwards);
            animation-timing-function: linear;
        }
    }
    @keyframes section-0 {
        // променя - избледнява, opacity на background-a на section-0
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }


    #txt-0-1 {
        @include transform(translateX(100%));
        animation-name: txt-0-1;
        animation-duration: 5s;
        @include animation-fill-mode(forwards);
        animation-timing-function: linear;
    }
    @keyframes txt-0-1 {
        0% {
            @include transform(translateX(100%));
        }
        100% {
            @include transform(translateX(-100%));
        }
    }

    #txt-0-2 {
        @include transform(translateX(-100%));
        animation-name: txt-0-2;
        animation-duration: 5s;
        @include animation-fill-mode(forwards);
        animation-timing-function: linear;
    }
    @keyframes txt-0-2 {
        0% {
            @include transform(translateX(-100%));
        }
        100% {
            @include transform(translateX(100%));
        }
    }
}

.section-0.state,
.section-0.inactive {
    display:none;
    &#section-0 {
        opacity: 0;
        &:before {
            opacity: 0;
        }
    }
    #logo-0 {
        @include transform(translateY(-50%));
    }
    #h1-0 {
        opacity: 0;
    }
    #txt-0-1 {
        @include transform(translateX(-100%));
    }

    #txt-0-2 {
        @include transform(translateX(100%));
    }
}
