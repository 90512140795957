@import "../../base.scss";

.section-4 {
    text-align: center;

    .section-header {
        border: 0;
        &:before {
            left: 0;
            background-image: url("/imgs/dash.png");
        }
        &:after {
            right: 0;
            background-image: url("/imgs/dash-white.png");
        }
        .txt {
            color:#ffffff;
        }
        .line-icon {
            left: 75%;
        }
    }


    .cell-2 {
        &:before { // triggers mouse wheel
            z-index:1;
            position: absolute;
            width:100%;
            height:100%;
            top:0;
            left:0;
            content:"";
            display:block;
        }
        z-index: 2;
        position: absolute;
        width: 50%;
        height: 100%;
        top: 0;
        right: 0;
        overflow: hidden;
        .video-logo {
            z-index: 1;
            color: #ffffff;
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            text-align: center;
            h2 {
                font-weight: $font-bold;
                text-transform: uppercase;
                padding-top: 20px;
                color: #ffffff;
            }
            img {
                display: block;
                margin: auto;
                width: 18vw;
                height: auto;
            }
        }
        .video-bg {
            background-image: url("/imgs/section-4.jpg");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .cell-1 {
        z-index: 1;
        position: absolute;
        width: 50%;
        height: 100%;
        top: 0;
        left: 0;
        @extend %flex;
        align-items: center;
        justify-content: center;
        background-image: url("/imgs/europe-bg.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center;

        padding-top: 120px;
        @media all and (max-height: 700px) {
            padding-top: 100px;
        }

        > div {
            z-index: 2;
            position: relative;
            text-align: left;
            width: $content-width;
            padding: 0 30px;
            @media all and (max-width: 1280px) {
                padding: 0 10px;
            }
            @media all and (max-height: 700px) {
                padding: 0 15px;
            }

            h2 {
                padding-bottom: 60px;
                @media all and (max-width: 1280px) {
                    padding-bottom: 30px;
                }
                @media all and (max-height: 800px) {
                    padding-bottom: 30px;
                }
                @media all and (max-height: 680px) {
                    padding-bottom: 20px;
                }
            }
            h4 {
                padding-bottom: 40px;
                @media all and (max-width: 1280px) {
                    padding-bottom: 30px;
                }
                @media all and (max-height: 800px) {
                    padding-bottom: 30px;
                }
                @media all and (max-height: 680px) {
                    padding-bottom: 20px;
                }
            }
            ul {
                li {
                    padding-bottom: 30px;
                    &:before {
                        background-color: #5c24fb;
                    }
                    @media all and (max-width: 1280px) {
                        padding-bottom: 15px;
                    }
                    @media all and (max-height: 680px) {
                        padding-bottom: 15px;
                    }
                }
                padding-bottom: 15px;
                @media all and (max-width: 1280px) {
                    padding-bottom: 7px;
                }
                @media all and (max-height: 680px) {
                    padding-bottom: 7px;
                }
            }
            p {
                padding-bottom: 30px;
                &:last-child {
                    padding-bottom: 0;
                }
                @media all and (max-width: 1280px) {
                    padding-bottom: 15px;
                }
                @media all and (max-height: 600px) {
                    padding-bottom: 15px;
                }
            }


        }
    }
}

/* ANIMATIONS

    За да се стартира анимацията се добавя "animated-off" към "section-2" и клас "animated" към "section-3"
*/

.section-4.animated {

    #video-logo-4 {
        @include transform(translateY(-30%));
        opacity: 1;
        animation-name: video-logo-4;
        animation-duration: 2s;
        animation-delay: 1s;
        @include animation-fill-mode(forwards);
        animation-timing-function: linear;
    }
    @keyframes video-logo-4 {
        0% {
            @include transform(translateY(-30%));
            opacity: 1;
        }
        80% {
            @include transform(translateY(-50%));
            opacity: 1;
        }
        100% {
            @include transform(translateY(-50%));
            opacity: 0;
        }
    }

    #video-txt-4 {
        opacity: 0;
        animation-name: video-txt-4;
        animation-duration: 2s;
        animation-delay: 1s;
        @include animation-fill-mode(forwards);
        animation-timing-function: linear;
    }
    @keyframes video-txt-4 {
        0% {
            opacity: 0;
        }
        80% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    #video-box-4 {
        width: 100%;
        animation-name: video-box-4;
        animation-duration: 1s;
        animation-delay: 3s;
        @include animation-fill-mode(forwards);
        animation-timing-function: linear;
    }
    @keyframes video-box-4 {
        0% {
            width: 100%;
        }
        100% {
            width: 50%;
        }
    }

    #cell-text-4,
    #section-header-4 {
        opacity: 0;
        animation-name: cell-text-4;
        animation-duration: 1s;
        animation-delay: 3s;
        @include animation-fill-mode(forwards);
        animation-timing-function: linear;
    }
    @keyframes cell-text-4 {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}

/* NOTANIMATED , STATE */

.section-4.state {
    opacity: 1;
    #video-logo-4,
    #video-txt-4 {
        opacity: 0;
        display: none;
    }
    #video-box-4 {
        width: 50%;
    }
    #cell-text-4,
    #section-header-4 {
        opacity: 1;
    }
}

/* ANIMATED-OFF*/

.section-4.animated-off {
    animation-name: section-4-off;
    animation-duration: 1s;
    animation-delay: 0.5s;
    @include animation-fill-mode(forwards);
    animation-timing-function: linear;
    @keyframes section-4-off {
        0% {
            @include transform(translateX(0));
        }
        100% {
            @include transform(translateX(-100%));
        }
    }

    #video-logo-4,
    #video-txt-4 {
        opacity: 0;
        display: none;
    }

    #video-box-4 {
        width: 50%;
        animation-name: video-box-4-off;
        animation-duration: 0.5s;
        @include animation-fill-mode(forwards);
        animation-timing-function: linear;
    }
    @keyframes video-box-4-off {
        0% {
            width: 50%;
        }
        100% {
            width: 100%;
        }
    }

    #cell-text-4,
    #section-header-4 {
        opacity: 1;
        animation-name: cell-text-4-off;
        animation-duration: 0.5s;
        @include animation-fill-mode(forwards);
        animation-timing-function: linear;
    }
    @keyframes cell-text-4-off {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
} // &.animated-off
