@import "../../base";

.home-m {
  overflow: hidden;

  .loading-screen {
    z-index: 10;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    @extend %flex;
    align-items: center;
    justify-content: center;

    * {
      color: #ffffff;
    }

    .logo {
      margin: 0 auto;
      font-size: $font-size-xl;
      font-family: $font-bold;
      width: 80vw;
      padding-bottom: 30px;
      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
    .txt {
      font-size: $font-size-md;
      font-weight: $font-bold;
      text-transform: uppercase;
    }


    // background: $base-color;
    // background: linear-gradient(45deg, rgba(110, 62, 152, 1) 0%, rgba(92, 36, 251, 1) 100%);

    background: $base-color;
    background: linear-gradient(45deg, #6E3E98, #5C24FB);
    background-size: 200% 200%;

    animation-name: m-loading-screen;
    animation-duration: 5s;
    @include animation-fill-mode(forwards);
    animation-timing-function: linear;

    @keyframes m-loading-screen {
      0% {
        opacity:1;
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
        opacity:1;
      }
      60% {
        opacity:1;
      }
      100% {
        background-position: 0% 50%;
        opacity:0;
        z-index:-1;
      }
    }
  }

  .line-icon {
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      width: 100%;
      height: 1px;
      overflow: hidden;
      background-image: url("/imgs/dash.png");
      background-repeat: repeat-x;
      background-position: center center;
      background-size: 15px auto;
    }
    text-align: center;
    img {
      margin: auto;
      display: block;
      height: 25px;
      width: auto;
      max-width: none;
    }
  }

  .video-bg img {
    display: block;
    width: 100%;
    height: auto;
  }

  .section-m-title {
    z-index: 5;
    opacity: 0.05;
    position: absolute;
    left: 0;
    @include transform(translateY(-55%));
    h2 {
      margin: 0;
      padding: 0 5px;
      color: $base-color;
      font-size: 6em;
      line-height: 1em;
      font-weight: $font-regular;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .section-m {
    position: relative;
    z-index: 1;
    padding-top: 30px;
    padding-bottom: 30px;
    background: rgba(92, 36, 251, 0.03);
    background: linear-gradient(45deg, rgba(92, 36, 251, 0.03) 0%, rgba(110, 62, 152, 0.03) 100%);

    h4 {
      margin: 0;
      padding: 0 0 30px 0;
      font-size: 20px;
    }
    p,
    ul {
      font-size: 16px;
      line-height: 1.4em;
    }
    p {
      padding-bottom: 30px;
      &:last-child {
        padding-bottom: 0;
      }
    }
    ul {
      padding-bottom: 30px;
      li {
        padding-bottom: 30px;
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
  .section-m-5 {z-index:0;}

  .section-bg {
    background-color: $base-color;
    color: #ffffff;
    * {
      color: #ffffff;
    }
    .line-icon {
      &:before {
        background-image: url("/imgs/dash-white.png");
      }
    }
    .bullets {
      li {
        &:before {
          background-color: #ffffff;
        }
      }
    }
  }

  .section-bg-2 {
    padding-top: 40px;
    padding-bottom: 40px;
    background-image: url("/imgs/europe-bg-m.jpg");
  }

  .funplangraph-wrap {
    position: relative;
    padding-bottom: 60px;
  }

  .video-responsive {
    overflow: hidden;
    width: 100vw;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  .video-responsive.m-video-1:after {
    padding-top: 52%;
  }
  .video-responsive.m-video-4:after {
    padding-top: 52%;
  }
  .video-responsive.m-video-5:after {
    padding-top: 52.5%;
  }

  .video-responsive.m-video-1 {
    background-image: url("/imgs/section-1-m.jpg");
  }
  .video-responsive.m-video-2 {
    background-image: url("/imgs/section-2-m.jpg");
  }
  .video-responsive.m-video-3 {
    background-image: url("/imgs/section-3-m.jpg");
  }
  .video-responsive.m-video-4 {
    background-image: url("/imgs/section-4-m.jpg");
  }
  .video-responsive.m-video-5 {
    background-image: url("/imgs/section-5-m.jpg");
  }
}

.m-animatedtext {
  opacity: 0;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  -webkit-transform: translate3d(0, 10px, 0) rotate(10deg);
  transform: translate3d(0, 10px, 0) rotate(10deg);
  -webkit-transition: opacity 0.3s linear, -webkit-transform 0.6s cubic-bezier(0, 0, 0.3, 1);
  transition: opacity 0.3s linear, -webkit-transform 0.6s cubic-bezier(0, 0, 0.3, 1);
  transition: opacity 0.3s linear, transform 0.6s cubic-bezier(0, 0, 0.3, 1);
  transition: opacity 0.3s linear, transform 0.6s cubic-bezier(0, 0, 0.3, 1),
    -webkit-transform 0.6s cubic-bezier(0, 0, 0.3, 1);
  will-change: opacity, transform;
}
.m-animatedtext-on {
  transition-delay: 500ms;
  opacity: 1;
  transform: translate3d(0px, 0px, 0px) rotate(0deg);

  &.m-animatedtext-delay {
    transition-delay: 5s;
  }
}

.m-anim-video-box {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.m-anim-video-hidden {
  position: relative;
  width: 0;
  overflow: hidden;
}

.m-anim-video {
  position: relative;
  width: 0;
  overflow: hidden;

  animation-name: m-anim-video;
  animation-duration: 1s;
  animation-delay: 0.5s;
  @include animation-fill-mode(forwards);
  // animation-timing-function: linear;

  .mask {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background-color: $base-color;

    animation-name: m-anim-video-mask;
    animation-duration: 1s;
    animation-delay: 1.5s;
    @include animation-fill-mode(forwards);

    &.mask-color-1 {
      background-color: $base-color;
    }
    &.mask-color-2 {
      background-color: #a31bcb; //$color-1;
    }
    &.mask-color-3 {
      background-color: #c59eea; //$color-2;
    }
    &.mask-color-4 {
      background-color: #a05fd8; //$color-3;
    }
    &.mask-color-5 {
      background-color: #dcc7ed; //$color-4;
    }
  }
  .video-responsive {
    z-index: 0;
  }

  &.m-anim-video-delay {
    animation-delay: 5s;
    .mask {
      animation-delay: 6s;
    }
  }


}

@keyframes m-anim-video {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes m-anim-video-mask {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
