@import "../../base.scss";

.section-3 {
    text-align: center;

    .section-header {
        border: 0;
        &:before {
            left: 0;
            background-image: url("/imgs/dash-white.png");
        }
        &:after {
            right: 0;
            background-image: url("/imgs/dash.png");
        }
        .line-icon {
            left: 50%;
        }
    }

    .txt-overlay {
        z-index: 4;
        position: absolute;
        top: 20%;
        left:100%;
        font-size: $font-size-xxxl;
        white-space: nowrap;
        color: $color-3;
        font-family: "Bebas", sans-serif;
        opacity: 0.05 !important;
    }

    .cell-1 {
        &:before { // triggers mouse wheel
            z-index:1;
            position: absolute;
            width:100%;
            height:100%;
            top:0;
            left:0;
            content:"";
            display:block;
        }
        z-index: 2;
        position: absolute;
        width: 50%;
        height: 100%;
        top: 0;
        left: 0;
        overflow: hidden;
        .video-logo {
            z-index: 1;
            color: #ffffff;
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            text-align: center;
            h2 {
                font-weight: $font-bold;
                text-transform: uppercase;
                padding-top: 20px;
                color: #ffffff;
            }
            img {
                display: block;
                margin: auto;
                width: 18vw;
                height: auto;
            }
        }
        .video-bg {
            background-image: url("/imgs/section-3.jpg");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
    .cell-2 {
        z-index: 1;
        position: absolute;
        width: 50%;
        height: 100%;
        top: 0;
        right: 0;
        @extend %flex;
        align-items: flex-end;
        justify-content: center;
        > div {
            text-align: left;
            width: $content-width;
            padding-bottom: 60px;
            h2 {
                padding-bottom: 50px;
            }
            ul {
                li {
                    padding-bottom: 30px;
                }
                padding-bottom: 15px;
            }
            p {
                padding-bottom: 30px;
                &:last-child {
                    padding-bottom: 0;
                }
            }
            @media all and (max-height:600px){
                padding-bottom:30px;
                h2 {
                    padding-bottom:30px;
                }
                ul {
                    li {
                        padding-bottom: 15px;
                    }
                    padding-bottom: 7px;
                }
            }
        }
    }
}

/* ANIMATIONS

    За да се стартира анимацията се добавя "animated-off" към "section-2" и клас "animated" към "section-3"
*/

.section-3.animated {

    #video-logo-3 {
        @include transform(translateY(-30%));
        opacity: 1;
        animation-name: video-logo-3;
        animation-duration: 2s;
        animation-delay: 1s;
        @include animation-fill-mode(forwards);
        animation-timing-function: linear;
    }
    @keyframes video-logo-3 {
        0% {
            @include transform(translateY(-30%));
            opacity: 1;
        }
        80% {
            @include transform(translateY(-50%));
            opacity: 1;
        }
        100% {
            @include transform(translateY(-50%));
            opacity: 0;
        }
    }

    #video-txt-3 {
        opacity: 0;
        animation-name: video-txt-3;
        animation-duration: 2s;
        animation-delay: 1s;
        @include animation-fill-mode(forwards);
        animation-timing-function: linear;
    }
    @keyframes video-txt-3 {
        0% {
            opacity: 0;
        }
        80% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    #video-box-3 {
        width: 100%;
        animation-name: video-box-3;
        animation-duration: 1s;
        animation-delay: 3s;
        @include animation-fill-mode(forwards);
        animation-timing-function: linear;
    }
    @keyframes video-box-3 {
        0% {
            width: 100%;
        }
        100% {
            width: 50%;
        }
    }

    #txt-3 {
        @include transform(translateX(0));
        animation-name: txt-3;
        animation-duration: 1s;
        animation-delay: 3s;
        @include animation-fill-mode(forwards);
        animation-timing-function: linear;
    }
    @keyframes txt-3 {
        0% {
            @include transform(translateX(0));
        }
        100% {
            @include transform(translateX(-100%));
        }
    }

    #cell-text-3,
    #section-header-3 {
        opacity: 0;
        animation-name: cell-text-3;
        animation-duration: 1s;
        animation-delay: 3s;
        @include animation-fill-mode(forwards);
        animation-timing-function: linear;
    }
    @keyframes cell-text-3 {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}


/* NOTANIMATED , STATE */

.section-3.state {
    opacity: 1;
    #video-logo-3,
    #video-txt-3 {
        opacity: 0;
        display: none;
    }
    #video-box-3 {
        width: 50%;
    }
    #txt-3 {
        @include transform(translateX(-100%));
    }
    #cell-text-3,
    #section-header-3 {
        opacity: 1;
    }
}


/* ANIMATED-OFF*/

.section-3.animated-off {
    animation-name: section-3-off;
    animation-duration: 1s;
    animation-delay: 0.5s;
    @include animation-fill-mode(forwards);
    animation-timing-function: linear;
    @keyframes section-3-off {
        0% {
            @include transform(translateX(0));
        }
        100% {
            @include transform(translateX(100%));
        }
    }

    #video-logo-3,
    #video-txt-3 {
        opacity: 0;
        display: none;
    }

    #video-box-3 {
        width: 50%;
        animation-name: video-box-3-off;
        animation-duration: 0.5s;
        @include animation-fill-mode(forwards);
        animation-timing-function: linear;
    }
    @keyframes video-box-3-off {
        0% {
            width: 50%;
        }
        100% {
            width: 100%;
        }
    }

    // #txt-3,
    #cell-text-3,
    #section-header-3 {
        opacity: 1;
        animation-name: cell-text-3-off;
        animation-duration: 0.5s;
        @include animation-fill-mode(forwards);
        animation-timing-function: linear;
    }
    @keyframes cell-text-3-off {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

 
} // &.animated-off
